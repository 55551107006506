import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueObserveVisibility from 'vue-observe-visibility'
import VBodyScrollLock from 'v-body-scroll-lock'
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.body.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.body.style.setProperty('--vh', `${vh}px`);
});


window.onload=function(){
  document.querySelector(".welcome .click").click();
};

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)

new Vue({
  el: "#app",
  delimiters: ["[[", "]]"],
  components: {
LocalSwiper: VueAwesomeSwiper.swiper,
LocalSlide: VueAwesomeSwiper.swiperSlide,
},
  data() {
      return {
          csrfName: window.csrfTokenName,
          csrfToken: window.csrfTokenValue,
          swiperGallery: {
          loop: false,
          loopedSlides: 5, // looped slides should be the same
          spaceBetween: 0,
          dynamicBullets:true,
          spaceBetween: 20,
          slidesPerView: 'auto',
          touchRatio: 0.2,
          slideToClickedSlide: true,
        },
  }
}
});

const Utils = {

  addClass: function(element, theClass) {
    element.classList.add(theClass);
  },

  removeClass: function(element, theClass) {
    element.classList.remove(theClass);
  },

  showMore: function(element, excerpt) {
    element.addEventListener("click", event => {
      const linkText = event.target.textContent.toLowerCase();
      event.preventDefault();

      console.log(this);
      if (linkText == "show more") {
        element.textContent = "Show less";
        this.removeClass(excerpt, "excerpt-hidden");
        this.addClass(excerpt, "excerpt-visible");
      } else {
        element.textContent = "Show more";
        this.removeClass(excerpt, "excerpt-visible");
        this.addClass(excerpt, "excerpt-hidden");
      }
    });
  }
};

const ExcerptWidget = {
  showMore: function(showMoreLinksTarget, excerptTarget) {
   const showMoreLinks = document.querySelectorAll(showMoreLinksTarget);

   showMoreLinks.forEach(function(link) {
     const excerpt = link.previousElementSibling.querySelector(excerptTarget);
     Utils.showMore(link, excerpt);
   });
  }
};

ExcerptWidget.showMore('.js-show-more', '.js-excerpt');
