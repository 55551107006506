
<template>
<div class="example">
  <swiper class=" swiper w-full" style="height: calc(var(--vh, 1vh) * 100);" :options="swiperOption" ref="mySwiper">

    <swiper-slide v-for="(slide, index) in slides" class="w-full flex-col  flex">
      <div class="w-full relative flex flex-col md:hidden">
        <div class="xs:h-16 w-full" >
              <div class="swiper-pagination grid grid-cols-2 xs:grid-cols-4" :style="'font-weight:800; margin-right:25px; background-color:'+ slide.colour + '!important'"></div>

        </div>
      </div>
      <div class="h-full w-full">
        <div :style="'background-color:'+ slide.colour" class="absolute left-0 top-0 bottom-0 w-full md:w-1/2">

        </div>

        <div class="swiper-zoom-container w-full h-full flex justify-start flex-col-reverse md:flex-row md:justify-between relative md:px-12 xl:px-24">
          <div class="w-full md:w-1/2 mt-8 md:mt-0 px-6 md:px-0 text-left text-white absolute md:relative md:top-0 bottom-0 left z-20 title-box" :style="'background: linear-gradient(0deg, rgba(' + slide.red + ',' + slide.green + ',' + slide.blue +',1) 0%, rgba(' + slide.red + ',' + slide.green + ',' + slide.blue +',0) 100%);'">
            <h1 class="md:max-w-sm lg:max-w-xl mb-6 md:mb-12 -mt-32 md:mt-0 relative z-20 " >{{slide.title}}</h1>
            <div class="flex md:flex-col lg:flex-row">
              <a v-if="slide.buttonLink" class="text-white bg-white border-2 border-white px-8 py-4 text-sm sm:text-base md:text-xl md:px-12 md:py-6 relative z-20" :class="{hidden: !slide.showButton}"  :style="'width:fit-content; color:'+ slide.colour" :href="slide.buttonLink">{{slide.button}}</a>
              <span v-else class="text-white bg-white border-2 border-white px-8 py-4 text-sm sm:text-base md:text-xl md:px-12 md:py-6 relative z-20 opacity-75 " :class="{hidden: !slide.showButton}"  :style="'width:fit-content; color:'+ slide.colour" >{{slide.button}}</span>
              <a v-if="slide.secondLink" class="text-white underline px-8 py-4 text-sm sm:text-base md:text-xl md:px-12 md:py-6 relative z-20 text-white"  :href="slide.secondLink">More Info</a>
            </div>
                    </div>

          <div class=" mx-auto  slider-image bg-cover flex  md:justify-center items-center top-0 md:inset-0 md:absolute " >
            <div v-if="slide.videoLink" class="media-wrapper md:rounded-full bg-cover bg-center relative overflow-hidden" :style="'background-image:url('+ slide.image + '); padding:24%;'">

              <video :src="slide.videoLink" class="banner-vid absolute w-auto h-full w-full inset-0" playsinline muted loop autoplay :poster="'background-image:url('+ slide.image + ');'" style="object-fit:cover"></video>
            </div>
            <div v-else="slide.video" class="media-wrapper md:rounded-full bg-cover bg-center relative " :style="'background-image:url('+ slide.image + '); padding:24%;'">
            </div>
          </div>
          <div class="w-full md:w-1/3  hidden md:flex flex-col z-20 absolute top-0 bottom-0 right-0 justify-center" style="align-items:flex-end;">
            <div class=" " style="">
                  <div class="swiper-pagination w-56 lg:w-72 xl:w-84 text-xl xl:text-2xl flex flex-col items-start" :style="'font-weight:800; color:'+ slide.colour"></div>

            </div>
          </div>
      </div>


      </div>
    </swiper-slide>

  </swiper>
</div>
</template>

<script>
import {
  Swiper,
  SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

var menu = [, '<span class="inner-span"><span class="dash"></span>LexPlay</span>', '<span class="inner-span"><span class="dash"></span>Laser Force</span>','<span class="inner-span"><span class="dash"></span>Our New Space</span>']

export default {
  name: 'custom-slider',
  title: 'Slider',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['slides'],
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: true,
        updateOnWindowResize: true,
        effect: 'fade',
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: this.generateTab,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

      }
    }
  },
  methods: {
    toSlide(index) {
      this.$refs.mySwiper.$swiper.slideTo(index, 0)
    },
    generateTab(index, className) {
      console.log(index, this.slides[index])
      return `<span class="test ${className}"><span class="inner-span"><span class="dash"></span>${this.slides[index].tab}</span></span>`
    }
  },
}
</script>

<style lang="scss" scoped>


.example {

    .swiper {
        width: 100%;

        .swiper-slide {

            justify-content: space-between;
            align-items: center;
            text-align: center;
            font-weight: bold;

            .swiper-zoom-container{
                justify-content: space-between;
                      @media screen and (max-width:767px){
  justify-content: flex-end;

                      }
            }
        }
    }

    .swiper-pagination{
      position: relative !important;
    }

    .toolbar {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 2rem;

        button {
            flex: 1;
            padding: 0;
            margin: 0;

            cursor: pointer;
            &:last-child {
                border: none;
            }

            &:hover {}
        }
    }
}
</style>
