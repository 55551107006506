<template>
    <Slide noOverlay right :crossIcon="true" >
      <div style="display:block;" v-for="navItem in nodes">
        <a id="home" :href="navItem.url" class="block text-white hover:font-bold hover:text-white">
          <span>{{ navItem.title }}</span>

        </a>
        <div v-if="navItem.subnodes">
          <div class="block" v-for="subNode in navItem.subnodes">
          <a class="text-white text-sm hover:font-bold hover:text-white list-disc" :href="subNode.url">- {{subNode.title}}</a>
        </div>
          </div>
      </div>
    </Slide>
</template>

<script>
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  props:{
    nodes: Array,
  },
    components: {
        Slide // Register your component
    }
}
</script>
<style>
.bm-burger-button{
  top:0px !important;
  right:0px !important;
  bottom: 0px !important;
  margin:auto;
}

.line-style{
  height: 10%;
}

.bm-cross{
  width: 3px !important;
  height: 38px !important;
}
</style>
